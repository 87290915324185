import React from 'react';
import api from '../../../services/api';
import Loading from '../../Loading';
import { Link } from 'react-router-dom'; //usado para linkar outras paginas.
import { Dropdown } from 'semantic-ui-react'
import { Modal, Button } from 'react-bootstrap'
import { FiEdit, FiTrash2, FiInfo, FiChevronLeft } from 'react-icons/fi';
import { Context } from '../../../Context/AuthContext';
import ReactTooltip from 'react-tooltip';

import './styles.css';

import { Menu } from '../../../global.js';

import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom'; //usado para linkar outras paginas.
import {MedidasControle, cadastrarMedidas} from '../MedidasControle';
import {TrabalhadoresExpostos, cadastrarTrabalhador} from '../TrabalhadoresExpostos';


import 'semantic-ui-css/semantic.min.css';

export default function CadastrarRisco() {

	const [riscoId, setRiscoId] = useState(0)
	const tarefaId = sessionStorage.getItem('atividadeId')
	const estabelecimentoModalidade = sessionStorage.getItem('estabelecimentoModalidade')
	const tarefa = sessionStorage.getItem('tarefa')
	const { aviso } = React.useContext(Context);

	const history = useHistory();

	const [ambiental, setAmbiental] = useState('')
	const [ergo, setErgo] = useState('')
	const [observ, setObserv] = useState('')
	const [fonte_externa, setFonteExterna] = useState('')
	const [severidade, setSeveridade] = useState('')
	const [probabilidade, setProbabilidade] = useState('')
	const [numTrabalhadores, setNumTrabalhadores] = useState('')
	const [resultado, setResultado] = useState('')
	const [cenarios, setCenarios] = useState('')
	const [abrirPlano, setAbrirPlano] = useState('')
	const [justificativa, setJustificativa] = useState('')
	const [matriz_propria, setMatriz] = useState('')
	const [ordem, setOrdem] = useState(null)

	const [treinamento, setTreinamento] = useState('')
	const [procedimentos, setProcedimentos] = useState('')
	const [regras, setRegras] = useState('')
	const [inspecoes, setInspecoes] = useState('')
	const [ergonomia, setErgonomia] = useState('')
	const [higiene, setHigiene] = useState('')
	const [engenharia, setEngenharia] = useState('')
	const [emergencia, setEmergencia] = useState('')
	const [epi, setEpi] = useState('')
	const [modalShow, setModalShow] = useState(false)
	const [modalShow2, setModalShow2] = useState(false)


	const t = sessionStorage.getItem("RiscoTipo") !== null ? sessionStorage.getItem("RiscoTipo") : 'Acidente'
	const [tipologia, setTipologia] = useState(t);
	const [carregando, setCarregando] = useState(false);
	const [state, setState] = useState([]);
	const [currentValues, setCurrentValues] = useState(""); // perigo
	const [state3, setState3] = useState([]);
	const [currentValues3, setCurrentValues3] = useState(""); //risco
	const [state2, setState2] = useState([]);
	const [currentValues2, setCurrentValues2] = useState([]); //lesoes

	const [showErg, setShowErg] = useState(false)
	const [showAmb, setShowAmb] = useState(false)

	const [trabalhadores, setTrabalhadores] = useState([])
	const [controles, setControles] = useState([])

	const [booleanNao, setBooleanNao] = useState(false)
	const [nao, setNao] = useState(false)

	const [booleanNao2, setBooleanNao2] = useState(false)
	const [nao2, setNao2] = useState(false)

	const [vin_pcmso, setVinPcmso] = useState('')
	const [vin_laudo, setVinLaudo] = useState('')
	const [pcmso_anexo, setPA] = useState('')
	const [pcmso_criterio, setPC] = useState('')
	const [pcmso_exames, setPE] = useState('')
	const [pcmso_periodicidade, setPP] = useState('')
	const [pcmso_risco, setPR] = useState('')
	const [pcmso_observ, setPO] = useState('')
	const [pcmso_cas, setPcas] = useState('')
	const [pcmso_anexo_ref, setPAR] = useState('')
	const [pcmso_risco_ref, setPRR] = useState('')
	const [pcmso_criterio_ref, setPCR] = useState('')
	const [searchQuery, setSearchQuery] = useState('')
	const [searchQuery2, setSearchQuery2] = useState('')
	const [searchQuery3, setSearchQuery3] = useState('')
	const [opcoes_lesoes, setOpLesoes] = useState([])
	const [opcoes_perigo, setOpPerigo] = useState([])
	const [opcoes_risco, setOpRisco] = useState([])

	const [modalLesao, setModalLesao] = useState(false)
	const [modalRisco, setModalRisco] = useState(false)
	const [modalPerigo, setModalPerigo] = useState(false)

	var textRisco = "Inserir o risco identificado na atividade.<br/><br/><br/>"
		+ "Risco - Combinação da probabilidade "
		+ "de uma ou mais exposições e a "
		+ "gravidade do dano que pode ser "
		+ "causado por eles."

	var textPerigo = "Inserir o perigo identificado na atividade e "
		+ "descrever em detalhes no campo perigo.<br/><br/><br/>"
		+ "Perigo - Fonte, situação ou ação com "
		+ "potencial de causar danos à saúde dos "
		+ "trabalhadores, em equipamentos ou "
		+ "instalações."

	var textLesoes = "Procurar uma doença ou lesão na Lista de "
		+ "Doenças Relacionadas ao Trabalho – A "
		+ "lista destina-se, no âmbito da saúde, às "
		+ "seguintes finalidades, entre outras: <br/><br/><br/>"
		+ "I – orientar o uso clínico-epidemiológico, de "
		+ "forma a permitir a qualificação da "
		+ "atenção integral à Saúde do Trabalhador;<br/>"
		+ "II – facilitar o estudo da relação entre o "
		+ "adoecimento e o trabalho;<br/>"
		+ "III – adotar procedimentos de diagnóstico; <br/>"
		+ "IV – elaborar projetos terapêuticos mais acurados;<br/>"
		+ "V – orientar as ações de vigilância e promoção da "
		+ "saúde em nível individual e coletivo."

	var textErgo = "Transferir a conclusão da AET – Análise "
		+ "Ergonômica do Trabalho de maneira permitir "
		+ "graduar a probabilidade de ocorrer um acidente ou lesão.<br/><br/><br/>"
		+ "E quando for qualitativa trazer o resultado da "
		+ "avaliação qualitativa. "

	var textAmbiental = "Transferir do estudo ambiental o "
		+ "resultado da intensidade ou "
		+ "concentração do risco avaliado, sempre "
		+ "comparando o limite de tolerância (LEO). "
		+ "E quando for qualitativo trazer o "
		+ "resultado da avaliação qualitativa."

	const handleChange = (e, { value }) => {

		if(tipologia === "Ergonômico - Riscos Psicossociais"){
			setCurrentValues3('');
			setCurrentValues2([]);
			
			const key_perigo = state.find(item => item.value === value)?.key;
			
			if (key_perigo) {
			  setCurrentValues3(state3.find(item => item.fk_perigo_sugest === key_perigo)?.value || '');
			
			  const newValue = state2.find(item => item.fk_perigo_sugest === key_perigo)?.value;
			  if (newValue) {
				setCurrentValues2(prevValues => [...prevValues, newValue]);
			  }
			}
		}
		setSearchQuery(value);
		setCurrentValues(value);
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleChange3 = (e3, { value }) => {
		setCurrentValues3(value);
		setSearchQuery3(value);
	}

	const handleSearchChange3 = (e, { searchQuery }) => {
		setSearchQuery3(searchQuery)
	}

	const handleChange2 = (e2, { value }) => {
		setCurrentValues2(value);
		setSearchQuery2('')
	}

	const handleSearchChange2 = (e, { searchQuery }) => {
		setSearchQuery2(searchQuery)
	}

	const handleLabelClick2 = (e, { value }) => {
		setCurrentValues2(currentValues2.filter((item) => item != value))
		setSearchQuery2(value)
	}

	const handleAddition2 = (e2, { value }) => {
		const copy2 = Array.from(state2);
		copy2.push({ key: value, text: value, value: value })
		setState2(copy2);
	}
	const handleAddition3 = (e3, { value }) => {
		const copy3 = Array.from(state3);
		copy3.push({ key: value, text: value, value: value })
		setState3(copy3);
	}
	const handleAddition = (e, { value }) => {
		const copy = Array.from(state);
		copy.push({ key: value, text: value, value: value })
		setState(copy);
	}

	const handleSugestoes = async (tipologia) => {

		await Promise.all([
			api.get('sugestoes/5?tipo=' + tipologia),
			api.get('sugestoes/6?tipo=' + tipologia),
			api.get('sugestoes/2')
		]).then(response => {
			setState(response[0].data.resultado)
			setOpPerigo(response[0].data.resultado)
			setState3(response[1].data.resultado)
			setOpRisco(response[1].data.resultado)
			setOpLesoes(response[2].data.resultado)
			setState2(response[2].data.resultado)
		})

	}

	const handleChangeTipologia = async (event) => {

		try {

			event.persist()
			setCarregando(true) //abri tela de loading
			setTipologia(event.target.value);
			var aux = event.target.value
			if (aux === "Acidente") {
				setShowAmb(false)
				setShowErg(false)
				setErgo('')
				setAmbiental('')
			} else if (aux === "Ergonômico" || aux === "Ergonômico - Riscos Psicossociais") {
				setShowAmb(false)
				setShowErg(true)
				setAmbiental('')
			} else {
				setShowErg(false)
				setShowAmb(true)
				setErgo('')
			}

			await handleSugestoes(aux);

			setCurrentValues3(''); // Zera valores selecionados
			setSearchQuery3('')
			setCurrentValues('');
			setSearchQuery('')
			setCurrentValues2([])
			setSearchQuery2('')

		} catch (error) {
			aviso('error', error)
		} finally {
			setCarregando(false) //fecha tela do loading
		}

	}

	useEffect(() => {

		setCarregando(true)

		async function initial() {

			try {
				var teste = null;
				if (sessionStorage.getItem('riscoNovo') === "true") {
					setRiscoId(0)
					teste = "0"
				} else {
					setRiscoId(sessionStorage.getItem('riscoId'))
					teste = sessionStorage.getItem('riscoId')
				}

				var tipo = tipologia;
				var ris
				var perigo
				var lesoes = []
				var aux = false

				if (teste !== "0") {
					await Promise.all([
						api.get(`risco?tarefa_id=${tarefaId}`),
						api.get(`danos_saude?risco_id=${teste}`),
						api.get(`trabalhadores_expostos?risco_id=${teste}`),
						api.get(`controles_existentes?risco_id=${teste}`),
					]).then(response => {

						setControles(response[3].data.resultado)
						response[0].data.resultado.forEach(risco => {
							if (risco.id === parseInt(teste)) {
								setTipologia(risco.tipologia)
								if (risco.tipologia === "Acidente") {
									setShowAmb(false)
									setShowErg(false)
								} else if (risco.tipologia === "Ergonômico" || risco.tipologia === "Ergonômico - Riscos Psicossociais") {
									setShowAmb(false)
									setShowErg(true)
								} else {
									setShowErg(false)
									setShowAmb(true)
								}
								tipo = risco.tipologia
								ris = risco.nome
								perigo = risco.perigo
								setCurrentValues(risco.perigo)
								setSearchQuery(risco.perigo)
								setCurrentValues3(risco.nome)
								setSearchQuery3(risco.nome)
								setObserv(risco.observacao)
								setFonteExterna(risco.fontes_externas)
								setErgo(risco.resultado_ergonomico)
								setAmbiental(risco.resultado_ambiental)
								setSeveridade(risco.severidade)
								setProbabilidade(risco.probabilidade)
								setNumTrabalhadores(risco.numero_trabalhadores)
								setResultado(risco.resultado)
								setCenarios(risco.cenario_emergencia)
								setAbrirPlano(risco.abrir_plano_acao)
								setJustificativa(risco.justificativa)
								setMatriz(risco.matriz_propria)
								setTreinamento(risco.treinamento)
								setProcedimentos(risco.procedimentos)
								setRegras(risco.regras)
								setInspecoes(risco.inspecoes)
								setErgonomia(risco.ergonomia)
								setHigiene(risco.higiene)
								setEngenharia(risco.engenharia)
								setEmergencia(risco.emergencia)
								setEpi(risco.epi)
								setOrdem(risco.ordem)
								if (risco.nao_apli == 0) {
									setBooleanNao(false)
									setNao(false)
								} else {
									setBooleanNao(true)
									setNao(true)
								}
								if (risco.nao_cont == 0) {
									setBooleanNao2(false)
									setNao2(false)
								} else {
									setBooleanNao2(true)
									setNao2(true)
								}
								setVinPcmso(risco.vin_pcmso)
								setVinLaudo(risco.vin_laudo)
								setPA(risco.pcmso_anexo)
								setPC(risco.pcmso_criterio)
								setPE(risco.pcmso_exames)
								setPP(risco.pcmso_periodicidade)
								setPO(risco.pcmso_observ)
								setPcas(risco.pcmso_cas)
								setPR(risco.pcmso_risco)
								setPAR(risco.pcmso_anexo_ref)
								setPRR(risco.pcmso_risco_ref)
								setPCR(risco.pcmso_criterio_ref)
							}
						})
						var valor = []
						response[1].data.resultado.filter(item => item.fk_riscos_id === parseInt(teste)).forEach(res => {
							valor.push(res.nome)
						})
						setCurrentValues2(valor) // lesoes
						lesoes = valor

						setTrabalhadores(response[2].data.resultado)

					})

				} else {

					if (sessionStorage.getItem("RiscoPerigo") !== null) {
						aux = true
						perigo = sessionStorage.getItem("RiscoPerigo")
						setCurrentValues(perigo)
						setSearchQuery(perigo)

						if (tipologia === "Acidente") {
							setShowAmb(false)
							setShowErg(false)
							setErgo('')
							setAmbiental('')
						} else if (tipologia === "Ergonômico" || tipologia === "Ergonômico - Riscos Psicossociais") {
							setShowAmb(false)
							setShowErg(true)
							setAmbiental('')
						} else {
							setShowErg(false)
							setShowAmb(true)
							setErgo('')
						}
					}

					if (sessionStorage.getItem("RiscoLesoes") !== null) {
						aux = true
						lesoes = JSON.parse(sessionStorage.getItem("RiscoLesoes"))
						setCurrentValues2(lesoes)
						setSearchQuery2('')
					}

				}

				await Promise.all([
					api.get('sugestoes/2'),
					api.get('sugestoes/6?tipo=' + tipo),
					api.get('sugestoes/5?tipo=' + tipo)
				]).then(response => {

					setOpLesoes(response[0].data.resultado)
					setOpRisco(response[1].data.resultado)
					setOpPerigo(response[2].data.resultado)


					if (teste != '0' || aux == true) {

						var verifica = false
						response[2].data.resultado.forEach(item => {
							if (perigo === item.value) {
								verifica = true
							}
						})
						if (!verifica) {
							response[2].data.resultado.push({ key: perigo, text: perigo, value: perigo })
						}

						lesoes.forEach(item => {
							var verifica2 = false
							response[0].data.resultado.forEach(item2 => {
								if (item === item2.value) {
									verifica2 = true
								}
							})
							if (!verifica2) {
								response[0].data.resultado.push({ key: item, text: item, value: item })

							}
						})

						var verifica3 = false
						response[1].data.resultado.forEach(item => {
							if (ris === item.value) {
								verifica3 = true
							}
						})

						if (!verifica3 && ris !== undefined) {
							response[1].data.resultado.push({ key: ris, text: ris, value: ris })

						}

					}

					setState2(response[0].data.resultado)
					setState3(response[1].data.resultado)
					setState(response[2].data.resultado)
				})

			} catch (error) {
				aviso('error', error)
			} finally {
				setCarregando(false)
			}
		}
		initial()
	}, [])

	const DropLesoes = React.useMemo(() =>
	(
		<Dropdown
			options={state2}
			placeholder="Selecione multiplos"
			search
			selection
			fluid
			multiple
			clearable
			lazyLoad={true}
			allowAdditions
			additionLabel="Adicionar "
			value={currentValues2}
			searchQuery={searchQuery2}
			onAddItem={handleAddition2}
			onChange={handleChange2}
			onSearchChange={handleSearchChange2}
			onLabelClick={handleLabelClick2}
		/>
	), [currentValues2, state2, searchQuery2])

	const DropRisco = React.useMemo(() =>
	(
		<Dropdown
			options={state3}
			placeholder="Selecione um"
			search
			lazyLoad={true}
			selection
			fluid
			clearable
			allowAdditions
			onSearchChange={handleSearchChange3}
			searchQuery={searchQuery3}
			additionLabel="Adicionar "
			value={currentValues3}
			onAddItem={handleAddition3}
			onChange={handleChange3}
		/>
	), [currentValues3, state3, searchQuery3])

	const DropPerigo = React.useMemo(() =>
	(
		<Dropdown
			options={state}
			placeholder="Selecione um"
			search
			selection
			fluid
			clearable
			lazyLoad={true}
			allowAdditions
			additionLabel="Adicionar "
			value={currentValues}
			searchQuery={searchQuery}
			onAddItem={handleAddition}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
		/>
	), [currentValues, state, searchQuery, state3, state2])


	const handleRegister = async (e) => {

		e.preventDefault()

		var data;

		if (riscoId == 0) {
			data = {
				nome: currentValues3,
				tipologia: tipologia,
				perigo: currentValues,
				resultado_ergonomico: ergo,
				resultado_ambiental: ambiental,
				cenario_emergencia: "",
				severidade: 0,
				probabilidade: 0,
				numero_trabalhadores: 0,
				resultado: 0,
				observacao: "",
				fontes_externas: "Não",
				abrir_plano_acao: "Não",
				justificativa: "",
				matriz_propria: "Não",
				treinamento: "",
				procedimentos: "",
				regras: "",
				inspecoes: "",
				ergonomia: "",
				higiene: "",
				engenharia: "",
				emergencia: "",
				epi: "",
				ordem: null,
				nao_apli: nao,
				nao_cont: nao2,
				vin_pcmso: "",
				pcmso_anexo: "",
				pcmso_anexo_ref: "",
				pcmso_risco: "",
				pcmso_risco_ref: "",
				pcmso_exames: "",
				pcmso_cas: "",
				pcmso_criterio: "",
				pcmso_criterio_ref: "",
				pcmso_periodicidade: "",
				pcmso_observ: "",
				vin_laudo: "",
			}

		} else {
			data = {
				nome: currentValues3,
				tipologia: tipologia,
				perigo: currentValues,
				resultado_ergonomico: ergo,
				resultado_ambiental: ambiental,
				cenario_emergencia: cenarios,
				severidade: severidade,
				probabilidade: probabilidade,
				numero_trabalhadores: numTrabalhadores,
				resultado: resultado,
				observacao: observ,
				fontes_externas: fonte_externa,
				abrir_plano_acao: abrirPlano,
				justificativa: justificativa,
				matriz_propria: matriz_propria,
				treinamento: treinamento,
				procedimentos: procedimentos,
				regras: regras,
				inspecoes: inspecoes,
				ergonomia: ergonomia,
				higiene: higiene,
				engenharia: engenharia,
				emergencia: emergencia,
				epi: epi,
				nao_apli: nao,
				nao_cont: nao2,
				ordem: ordem,
				vin_pcmso: vin_pcmso,
				pcmso_anexo: pcmso_anexo,
				pcmso_anexo_ref: pcmso_anexo_ref,
				pcmso_risco: pcmso_risco,
				pcmso_risco_ref: pcmso_risco_ref,
				pcmso_exames: pcmso_exames,
				pcmso_cas: pcmso_cas,
				pcmso_criterio: pcmso_criterio,
				pcmso_criterio_ref: pcmso_criterio_ref,
				pcmso_periodicidade: pcmso_periodicidade,
				pcmso_observ: pcmso_observ,
				vin_laudo: vin_laudo
			}
		}


		if ((nao == false && nao2 == false && controles.length <= 0) || trabalhadores.length < 1 || currentValues2.length < 1 || currentValues3 == '' || currentValues3 === null || tipologia === '' || currentValues === '' || (ambiental === '' && showAmb === true) || (ergo === '' && showErg === true)) {
			aviso('warning', "Preencha todos os campos!")
		} else {

			try {

				var mensagem
				setCarregando(true)
				var v_risco = false
				var v_perigo = false
				var i, j

				for (i = 0; i < opcoes_risco.length; i++) {
					if (currentValues3 == opcoes_risco[i].value) {
						v_risco = true
					}
				}

				if (!v_risco) {

					var valor = {
						text: currentValues3,
						value: currentValues3,
						filtro: tipologia
					}
					api.post('sugestoes/6', valor)
				}

				for (i = 0; i < opcoes_perigo.length; i++) {
					if (currentValues == opcoes_perigo[i].value) {
						v_perigo = true
					}
				}

				if (!v_perigo) {

					valor = {
						text: currentValues,
						value: currentValues,
						filtro: tipologia
					}
					api.post('sugestoes/5', valor)
				}

				var vv_lesao = false

				for (i = 0; i < currentValues2.length; i++) {
					var v_lesao = false
					for (j = 0; j < opcoes_lesoes.length; j++) {
						if (currentValues2[i] == opcoes_lesoes[j].value) {
							v_lesao = true
						}
					}

					if (!v_lesao) {

						var valor = {
							text: currentValues2[i],
							value: currentValues2[i]
						}
						api.post('sugestoes/2', valor)
						vv_lesao = true
					}
				}

				if (riscoId == 0) {

					await api.post(`risco?tarefa_id=${tarefaId}`, data).then(response => {
						sessionStorage.setItem('riscoId', response.data.id_risco)
						currentValues2.forEach(item => {
							var data2 = {
								nome: item
							}
							api.post("danos_saude?risco_id=" + response.data.id_risco, data2);
						})

						if(!nao && !nao2){
							cadastrarMedidas(controles, "post", response.data.id_risco, null);
						}
						cadastrarTrabalhador(trabalhadores, "post", response.data.id_risco, null);
					})

					sessionStorage.setItem("RiscoTipo", tipologia) //Implementando sugestão dinâmica
					sessionStorage.setItem("RiscoPerigo", currentValues) //Implementando sugestão dinâmica
					sessionStorage.setItem("RiscoLesoes", JSON.stringify(currentValues2)) //Implementando sugestão dinâmica

					mensagem = "Risco cadastrado com sucesso"

					if (vv_lesao || !v_perigo || !v_risco) {
						await handleSugestoes(tipologia)
					}

				} else {

					await api.delete("danos_saude/" + riscoId).then(async () => {
						await Promise.all([
							api.patch("risco/" + riscoId, data),
							currentValues2.forEach(item => {
								var data2 = {
									nome: item
								}
								api.post("danos_saude?risco_id=" + riscoId, data2)
							})
						])

					})

					const data2 = {
						nome: sessionStorage.getItem('tarefa'),
						descricao: sessionStorage.getItem('tarefaDescricao')
					}

					if (sessionStorage.getItem('revisao') != 'true') {
						await api.patch('tarefa/' + sessionStorage.getItem('atividadeId') + '?revisao=true', data2)
						sessionStorage.setItem('revisao', true)
					}

					mensagem = "Risco atualizado com sucesso"

					if (vv_lesao || !v_perigo || !v_risco) {
						await handleSugestoes(tipologia)
					}

				}
				sessionStorage.removeItem('riscoNovo');
				aviso('success', mensagem);
			} catch (error) {

				console.log(error)
				aviso('error', error)

			} finally {
				sessionStorage.setItem('riscoName', currentValues3)
				setCarregando(false)
				if (riscoId == 0)
					history.push('/avlrisco')

			}
		}
	}

	const handleRegister2 = async (e) => {

		e.preventDefault()

		var	data = {

			nome: currentValues3,
			tipologia: tipologia,
			perigo: currentValues,
			resultado_ergonomico: ergo,
			resultado_ambiental: ambiental,
			cenario_emergencia: cenarios,
			severidade: severidade,
			probabilidade: probabilidade,
			numero_trabalhadores: numTrabalhadores,
			resultado: resultado,
			observacao: observ,
			fontes_externas: fonte_externa,
			abrir_plano_acao: abrirPlano,
			justificativa: justificativa,
			matriz_propria: matriz_propria,
			treinamento: treinamento,
			procedimentos: procedimentos,
			regras: regras,
			inspecoes: inspecoes,
			ergonomia: ergonomia,
			higiene: higiene,
			engenharia: engenharia,
			emergencia: emergencia,
			epi: epi,
			ordem: ordem,
			nao_apli: nao,
			nao_cont: nao2,
			vin_pcmso: vin_pcmso,
			pcmso_anexo: pcmso_anexo,
			pcmso_anexo_ref: pcmso_anexo_ref,
			pcmso_risco: pcmso_risco,
			pcmso_risco_ref: pcmso_risco_ref,
			pcmso_exames: pcmso_exames,
			pcmso_cas: pcmso_cas,
			pcmso_criterio: pcmso_criterio,
			pcmso_criterio_ref: pcmso_criterio_ref,
			pcmso_periodicidade: pcmso_periodicidade,
			pcmso_observ: pcmso_observ,
			vin_laudo: vin_laudo
		}

		if ((nao == false && nao2 == false && controles.length < 1) || currentValues2.length < 1 || trabalhadores.length < 1 || currentValues3 == '' || currentValues3 === null || tipologia === '' || currentValues === '' || (ambiental === '' && showAmb === true) || (ergo === '' && showErg === true)) {
			aviso('warning', "Preencha todos os campos!")
		} else {

			try {
				setCarregando(true)
				var v_risco = false
				var v_perigo = false
				var i, j

				for (i = 0; i < opcoes_risco.length; i++) {
					if (currentValues3 == opcoes_risco[i].value) {
						v_risco = true
					}
				}

				if (!v_risco) {

					var valor = {
						text: currentValues3,
						value: currentValues3,
						filtro: tipologia
					}
					api.post('sugestoes/6', valor)
				}

				for (i = 0; i < opcoes_perigo.length; i++) {
					if (currentValues == opcoes_perigo[i].value) {
						v_perigo = true
					}
				}

				if (!v_perigo) {

					valor = {
						text: currentValues,
						value: currentValues,
						filtro: tipologia
					}
					api.post('sugestoes/5', valor)
				}

				var vv_lesao = false

				for (i = 0; i < currentValues2.length; i++) {
					var v_lesao = false
					for (j = 0; j < opcoes_lesoes.length; j++) {
						if (currentValues2[i] == opcoes_lesoes[j].value) {
							v_lesao = true
						}
					}

					if (!v_lesao) {

						var valor = {
							text: currentValues2[i],
							value: currentValues2[i]
						}
						api.post('sugestoes/2', valor)
						vv_lesao = true
					}
				}

				await api.delete("danos_saude/" + riscoId).then(async () => {
					await Promise.all([
						api.patch("risco/" + riscoId, data),
						currentValues2.forEach(item => {
							var data2 = {
								nome: item
							}
							api.post("danos_saude?risco_id=" + riscoId, data2)
						})
					])

				})

				const data2 = {
					nome: sessionStorage.getItem('tarefa'),
					descricao: sessionStorage.getItem('tarefaDescricao')
				}
				if (sessionStorage.getItem('revisao') != 'true') {
					await api.patch('tarefa/' + sessionStorage.getItem('atividadeId') + '?revisao=true', data2)
					sessionStorage.setItem('revisao', true)
				}
				if (vv_lesao || !v_perigo || !v_risco) {
					await handleSugestoes(tipologia)
				}
				
			} catch (error) {

				aviso('error', error)

			} finally {

				setCarregando(false)
				history.push('/avlrisco')
			}
		}
	}

	async function handleDeleteTrabalhador(id) {
		if (sessionStorage.getItem('riscoNovo')) {
			setTrabalhadores(trabalhadores.filter(trabalhador => trabalhador.id !== id))
		} else {
			try {
				await api.delete(`trabalhadores_expostos/${id}`)
				setTrabalhadores(trabalhadores.filter(trabalhador => trabalhador.id !== id))
				aviso('success', 'Trabalhador exposto removido com sucesso')
			} catch (error) {
				aviso('error', error)
			}
		}
	}

	async function UpdateTrabalhadores() {
		try {
			if (!sessionStorage.getItem('riscoNovo')) {
				await api.get(`trabalhadores_expostos?risco_id=${sessionStorage.getItem('riscoId')}`).then(response => {
					setTrabalhadores(response.data.resultado)
				})
			}
		} catch (error) {
			aviso('error', error)
		}
	}

	async function handleDeleteMedidas(id) {
		if (sessionStorage.getItem('riscoNovo')) {
			const novosControles = controles.filter(medida => medida.id !== id);
			setControles(novosControles)
		} else {
			try {
				setCarregando(true)
				await api.delete(`controles_existentes/${id}`)
				setControles(controles.filter(controle => controle.id !== id))
				aviso('success', 'Controle removido com sucesso')
			} catch (error) {
				aviso('error', error)
			} finally {
				setCarregando(false)
			}
		}
	}

	async function UpdateMedidas() {
		try {
			if (!sessionStorage.getItem('riscoNovo')) {
				setCarregando(true)
				await api.get(`controles_existentes?risco_id=${sessionStorage.getItem('riscoId')}`).then(response => {
					setControles(response.data.resultado)
				})
			}

		} catch (error) {
			aviso('error', error)
		} finally {
			setCarregando(false)
		}
	}

	const xNao = () => {
		if (booleanNao) {
			setNao(false)
		} else {
			setNao(true)
			setNao2(false)
			setBooleanNao2(false)
		}
		setBooleanNao(!booleanNao)
	}

	const xNao2 = () => {
		if (booleanNao2) {
			setNao2(false)
		} else {
			setNao2(true)
			setNao(false)
			setBooleanNao(false)
		}
		setBooleanNao2(!booleanNao2)
	}

	function addOrUpdateMedida(medida, id) {
		if (id !== null) {
			const updatedMedidas = controles.map(c =>
				c.id === parseInt(id) ? { ...c, ...medida } : c
			);
			setControles(updatedMedidas); // Atualiza o estado com o trabalhador modificado
		} else {
			const novaMedida = {
				...medida,
				id: Date.now() // Adiciona um id baseado no timestamp atual
			};
			setControles([...controles, novaMedida]);
		}
	}

	function addOrUpdateTrabalhador(trabalhador, id) {
		if (id !== null) {
			const updatedTrabalhadores = trabalhadores.map(t =>
				t.id === parseInt(id) ? { ...t, ...trabalhador } : t
			);
			setTrabalhadores(updatedTrabalhadores); // Atualiza o estado com o trabalhador modificado
		} else {
			const novoTrabalhador = {
				...trabalhador,
				id: Date.now() // Adiciona um id baseado no timestamp atual
			};
			setTrabalhadores([...trabalhadores, novoTrabalhador]); // Adiciona novo trabalhador se o id for null
		}
	}

	return (
		<div>
			<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux" effect="solid" event='click' globalEventOff='click' multiline={true}></ReactTooltip>
			<ReactTooltip backgroundColor="#006ab4" className="tip" id="aux2" effect="solid" multiline={true}></ReactTooltip>
			<RemoveLesao show={modalLesao} backdrop="static" onHide={() => { setModalLesao(false) }} />
			<RemoveRisco show={modalRisco} backdrop="static" onHide={() => { setModalRisco(false) }} />
			<RemovePerigo show={modalPerigo} backdrop="static" onHide={() => { setModalPerigo(false) }} />

			<Menu />
			<div style={{ textAlign: "left", marginLeft: "10px", marginTop: "5px" }}><h4>Organização: {sessionStorage.getItem('estabelecimentoName')}<br />{estabelecimentoModalidade === 'Tarefa' ? 'Tarefa' : 'Atividade'}: {tarefa}</h4></div>
			<div className="basic-content">

				<div className="titulo">
					<h1>{sessionStorage.getItem('riscoNovo') == "true" ? "Cadastrar" : "Editar"} risco</h1>
				</div>

				<div className="risco-box">

					<div className="back-button">
						<ReactTooltip effect="solid" />
						<Link to='/GerenciarRiscos'><i><FiChevronLeft data-tip="Voltar" /></i></Link>
					</div>

					<div className="risco-linha">
						<div className="risco-tipologia">
							<label htmlFor="risco-tipologia">Tipologia do risco</label>
							<select value={tipologia} onChange={handleChangeTipologia}>
								<option value="Acidente">Acidente</option>
								<option value="Ergonômico">Ergonômico</option>
								<option value="Ergonômico - Riscos Psicossociais">Ergonômico - Riscos Psicossociais</option>
								<option value="Físico">Físico</option>
								<option value="Químico">Químico</option>
								<option value="Biológico">Biológico</option>
							</select>
						</div>
						<div className="risco-risco">
							<label htmlFor="risco-risco">Perigo   <FiInfo style={{ cursor: "pointer" }} data-tip={textPerigo} data-for="aux"></FiInfo><FiTrash2 onClick={() => setModalPerigo(true)} style={{ cursor: "pointer" }} data-tip='Excluir sugestões' data-for="aux2" /></label>
							{DropPerigo}
						</div>

						<div className="risco-risco">
							<label htmlFor="risco-risco">Risco   <FiInfo style={{ cursor: "pointer" }} data-tip={textRisco} data-for="aux"></FiInfo><FiTrash2 onClick={() => setModalRisco(true)} style={{ cursor: "pointer" }} data-tip='Excluir sugestões' data-for="aux2" /></label>
							{DropRisco}
						</div>

					</div>


					<div className="risco-linha">
						<div className="risco-lesoes">
							<label htmlFor="risco-lesoes">Lesões ou agravos à saúde   <FiInfo style={{ cursor: "pointer" }} data-tip={textLesoes} data-for="aux"></FiInfo><FiTrash2 onClick={() => setModalLesao(true)} style={{ cursor: "pointer" }} data-tip='Excluir sugestões' data-for="aux2" /></label>
							{DropLesoes}
						</div>
						<div className="risco-metade5">
							<div className="risco-selecione2">
								<label htmlFor="">Resultado ergonômico   <FiInfo style={{ cursor: "pointer" }} data-tip={textErgo} data-for="aux"></FiInfo></label>
								<textarea className="text-area-risco" disabled={!showErg} type="longtext" aria-multiline="true" onChange={e => setErgo(e.target.value)} value={ergo} placeholder={showErg ? "Digite aqui" : ""} />
							</div>
							<div className="risco-selecione2">
								<label htmlFor="">Resultado ambiental   <FiInfo style={{ cursor: "pointer" }} data-tip={textAmbiental} data-for="aux"></FiInfo></label>
								<textarea className="text-area-risco" disabled={!showAmb} type="longtext" aria-multiline="true" onChange={e => setAmbiental(e.target.value)} value={ambiental} placeholder={showAmb ? "Digite aqui" : ""} />
							</div>
						</div>
					</div>

					<div className="medidas-1">
						<h3>Trabalhadores expostos ao risco</h3>
						<div>
							{trabalhadores.length ? trabalhadores.map(trabalhador => (
								<div key={trabalhador.id} className="ListaTrab-tarefa2">
									<ReactTooltip id={toString(trabalhador.id)} place="top" effect="solid" />
									<div>
										<h3 className="ListaTrab-nome2">{trabalhador.nome} / Qtd: {trabalhador.qtd}</h3>
									</div>
									<div className="ListaTrab-icones2">
										<i > <FiEdit data-tip={"Editar"} data-for={toString(trabalhador.id)} className="go-edit2" onClick={() => { sessionStorage.setItem('trabId', trabalhador.id); setModalShow(true) }} />  </i>
										<i>
											<FiTrash2 data-tip={"Ecluir"} data-for={toString(trabalhador.id) + trabalhador.nome}
												className="go-trash2"
												onClick={() => {
													const r = window.confirm('Tem certeza que deseja deletar esse trabalhador?')
													if (r === true) {
														handleDeleteTrabalhador(trabalhador.id);
													}
												}} />

										</i>
									</div>
								</div>
							)) : carregando ? null : <h3>Nenhum registro</h3>}
						</div>
						<div className="novo-elab">
							<button onClick={() => { sessionStorage.setItem("trabId", 0); setModalShow(true) }}>Cadastrar trabalhador exposto</button>
							<TrabalhadoresExpostos trabalhadores={trabalhadores} show={modalShow} addOrUpdateTrabalhador={addOrUpdateTrabalhador} backdrop="static" onHide={() => { setModalShow(false); UpdateTrabalhadores() }} />
						</div>
					</div>

					<div className="medidas-1">
						<h3>Medida de controle existente</h3>
						<div>
							{controles.length !== 0 ? controles.map(controle => (
								<div key={controle.id} className="ListaTrab-tarefa2">
									<ReactTooltip id={toString(controle.id)} place="top" effect="solid" />

									<div>
										<h3 className="ListaTrab-nome2">{controle.nome} / {controle.tipo}</h3>
									</div>
									<div className="ListaTrab-icones2">
										<i > <FiEdit data-tip={"Editar"} data-for={toString(controle.id)} className="go-edit2" onClick={() => { sessionStorage.setItem('controle_existente_id', controle.id); setModalShow2(true) }} />  </i>
										<i>
											<FiTrash2
												className="go-trash2" data-tip={"Excluir"} data-for={toString(controle.id)}
												onClick={() => {
													const r = window.confirm('Tem certeza que deseja deletar esse registro?')
													if (r === true)
														handleDeleteMedidas(controle.id)
												}} />

										</i>
									</div>

								</div>
							)) : <div>
								<div className="NaoApli">
									<label style={{ alignSelf: "center", marginRight: "8px", fontSize: "17px" }}>Não aplicável</label>
									<input style={{ width: "17px" }}
										type="checkbox"
										checked={booleanNao}
										onChange={xNao}
									/>

								</div>
								<div className="NaoApli">
									<label style={{ alignSelf: "center", marginRight: "8px", fontSize: "17px" }}>Não há controle</label>
									<input style={{ width: "17px" }}
										type="checkbox"
										checked={booleanNao2}
										onChange={xNao2}
									/>

								</div>
							</div>
							}
						</div>
						<div className="novo-elab">
							{!nao && !nao2 ? <button onClick={() => { sessionStorage.removeItem('controle_existente_id'); setModalShow2(true) }}>Cadastrar medida de controle</button> : null}
							<MedidasControle controles={controles} backdrop="static" show={modalShow2} addOrUpdateMedida={addOrUpdateMedida} onHide={() => { setModalShow2(false); sessionStorage.removeItem('controle_existente_id'); UpdateMedidas() }} />
						</div>
					</div>


					<div className={'cadastrar'}>
						<button onClick={handleRegister} >{riscoId == 0 ? "Cadastrar e avançar" : "Salvar"}</button>

						{riscoId !== 0 ? <button onClick={handleRegister2}>Avançar</button> : null}

					</div>


				</div>
				<Loading loading={carregando} message='Carregando...' />


			</div>
		</div>
	);
}

function RemoveLesao(props) {


	const [carregando, setCarregando] = useState(false);
	const [opLesao, setOpLesao] = useState([]);
	const [searchQuery, setSearchQuery] = useState('')
	const [lesao, setLesao] = useState([])

	const { aviso } = React.useContext(Context);

	useEffect(() => {
		if (props.show) {

			setCarregando(true)
			setOpLesao([])
			setLesao([])

			async function initial() {
				try {
					var f = await api.get('sugestoes/2')
					setOpLesao(f.data.resultado.filter(item => item.fk_user_id !== -1))
				} catch (error) {
					aviso('error', error)
					console.log(error)
				} finally {
					setCarregando(false)
				}

			}
			initial()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show])

	async function handleDelete(e) {
		e.preventDefault();

		if (lesao.length < 0) {
			aviso('warning', "Preencha todos os campos!")
		} else {
			try {
				setCarregando(true);
				var lista = []
				lesao.forEach(item => {
					lista.push(opLesao.filter(item2 => item2.value === item)[0].key)
				})

				var data = {
					lista: lista
				}

				await api.post('sugestoesDelete/2', data)
				aviso('success', 'Removido com sucesso');

			} catch (error) {
				aviso('error', error);
			} finally {
				setCarregando(false)
				setSearchQuery('')
				props.onHide()
			}
		}

	}

	const handleChange = (e, { value }) => {
		setLesao(value);
		setSearchQuery('')
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleLabelClick = (e, { value }) => {
		setLesao(lesao.filter((item) => item !== value))
		setSearchQuery(value)
	}


	const DropLesao = React.useMemo(() => (
		<Dropdown
			options={opLesao}
			placeholder={"Selecione múltiplos"}
			search
			selection
			lazyLoad={true}
			fluid
			multiple
			upward
			value={lesao}
			searchQuery={searchQuery}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
			onLabelClick={handleLabelClick}
		/>
	), [lesao, opLesao, searchQuery]);


	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Excluir sugestões
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Lesões ou agravos à saúde</label>
						{DropLesao}
					</div>

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleDelete} >Excluir</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function RemoveRisco(props) {


	const [carregando, setCarregando] = useState(false);
	const [opRisco, setOpRisco] = useState([]);
	const [searchQuery, setSearchQuery] = useState('')
	const [risco, setRisco] = useState([])

	const { aviso } = React.useContext(Context);

	useEffect(() => {
		if (props.show) {

			setCarregando(true)
			setOpRisco([])
			setRisco([])

			async function initial() {
				try {
					var f = await api.get('sugestoes/6?tipo=all');
					setOpRisco(f.data.resultado.filter(item => item.fk_user_id !== -1))
				} catch (error) {
					aviso('error', error)
					console.log(error)
				} finally {
					setCarregando(false)
				}

			}
			initial()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show])

	async function handleDelete(e) {
		e.preventDefault();

		if (risco.length < 0) {
			aviso('warning', "Preencha todos os campos!")
		} else {
			try {
				setCarregando(true);
				var lista = []
				risco.forEach(item => {
					lista.push(opRisco.filter(item2 => item2.value === item)[0].key)
				})

				var data = {
					lista: lista
				}

				await api.post('sugestoesDelete/6', data)
				aviso('success', 'Removido com sucesso');

			} catch (error) {
				aviso('error', error);
			} finally {
				setCarregando(false)
				setSearchQuery('')
				props.onHide()
			}
		}

	}

	const handleChange = (e, { value }) => {
		setRisco(value);
		setSearchQuery('')
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleLabelClick = (e, { value }) => {
		setRisco(risco.filter((item) => item !== value))
		setSearchQuery(value)
	}


	const DropRisco = React.useMemo(() => (
		<Dropdown
			options={opRisco}
			placeholder={"Selecione múltiplos"}
			search
			selection
			lazyLoad={true}
			fluid
			multiple
			upward
			value={risco}
			searchQuery={searchQuery}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
			onLabelClick={handleLabelClick}
		/>
	), [risco, opRisco, searchQuery]);


	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Excluir sugestões
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Risco</label>
						{DropRisco}
					</div>

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleDelete} >Excluir</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}

function RemovePerigo(props) {


	const [carregando, setCarregando] = useState(false);
	const [opPerigo, setOpPerigo] = useState([]);
	const [searchQuery, setSearchQuery] = useState('')
	const [perigo, setPerigo] = useState([])

	const { aviso } = React.useContext(Context);

	useEffect(() => {
		if (props.show) {

			setCarregando(true)
			setOpPerigo([])
			setPerigo([])

			async function initial() {
				try {
					var f = await api.get('sugestoes/5?tipo=all')
					setOpPerigo(f.data.resultado.filter(item => item.fk_user_id !== -1))
				} catch (error) {
					aviso('error', error)
					console.log(error)
				} finally {
					setCarregando(false)
				}

			}
			initial()
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.show])

	async function handleDelete(e) {
		e.preventDefault();

		if (perigo.length < 0) {
			aviso('warning', "Preencha todos os campos!")
		} else {
			try {
				setCarregando(true);
				var lista = []
				perigo.forEach(item => {
					lista.push(opPerigo.filter(item2 => item2.value === item)[0].key)
				})

				var data = {
					lista: lista
				}

				await api.post('sugestoesDelete/5', data)
				aviso('success', 'Removido com sucesso');

			} catch (error) {
				aviso('error', error);
			} finally {
				setCarregando(false)
				setSearchQuery('')
				props.onHide()
			}
		}

	}

	const handleChange = (e, { value }) => {
		setPerigo(value);
		setSearchQuery('')
	}

	const handleSearchChange = (e, { searchQuery }) => {
		setSearchQuery(searchQuery)
	}

	const handleLabelClick = (e, { value }) => {
		setPerigo(perigo.filter((item) => item !== value))
		setSearchQuery(value)
	}


	const DropPerigo = React.useMemo(() => (
		<Dropdown
			options={opPerigo}
			placeholder={"Selecione múltiplos"}
			search
			selection
			lazyLoad={true}
			fluid
			multiple
			upward
			value={perigo}
			searchQuery={searchQuery}
			onChange={handleChange}
			onSearchChange={handleSearchChange}
			onLabelClick={handleLabelClick}
		/>
	), [perigo, opPerigo, searchQuery]);


	return (
		<Modal
			{...props}
			size="sm"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Excluir sugestões
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Loading loading={carregando} message='Carregando...' />
				<div>

					<div style={{ display: "flex", flexDirection: "column", paddingBottom: "10px" }}>
						<label>Perigo</label>
						{DropPerigo}
					</div>

				</div>

			</Modal.Body>
			<Modal.Footer>
				<Button onClick={handleDelete}>Excluir</Button>
				<Button onClick={props.onHide}>Fechar</Button>
			</Modal.Footer>
		</Modal>
	);
}